
<template>
  <div id="app">
      <div class="w3-row banner houseColorBackground">
          <h4 class="w3-center"><b>Moodboard</b></h4>
          <i class="fa fa-bars w3-xxlarge rightSandwich w3-text-white w3-hover-text-gray w3-padding-small" @click="openRightMenu"></i>
          <div class="rightflex w3-sidebar w3-bar-block w3-card w3-animate-right" style="display:none;" id="rightMenu">
            <button @click="closeRightMenu" class="w3-bar-item w3-button w3-large houseFontColor">Schliessen &times;</button>
            <router-link @click="closeRightMenu" class="w3-bar-item w3-button houseFontColor" to="/">Home</router-link>
            <router-link @click="closeRightMenu" class="w3-bar-item w3-button houseFontColor" to="/mood">Mood</router-link>
          </div>
      </div>
      <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      start: true
    }
  },
  methods:{
    openRightMenu(){
      document.getElementById("rightMenu").style.display = "block";
    },
    closeRightMenu(){
      document.getElementById("rightMenu").style.display = "none";
    },
  }
}
</script>

<style>
body {
  overflow: hidden;
    max-width: 1280px;
    margin: auto;
    position: relative;
}

.houseColorBackground{
  background-color: #ff5722;
}

.houseFontColor{
  color: #ff5722;
}

.banner{
  color: white;
  margin: 10px;
  position: relative;
}
.rightflex{
  position:absolute!important;
  right:0;
  top:0;
  height: 827px;
}  

.rightSandwich{
  position:absolute!important;
  right:0;
  top:0;
}  

select{
  border: none;
  border-bottom: 2px solid #ff5722;
  padding: 9px 0;
  padding-left: 9px;
  width: 100%;
}
</style>

