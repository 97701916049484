import Vue from 'vue'
import App from './App.vue'
import VueKonva from 'vue-konva'
import Vue2Filters from 'vue2-filters'
import VueRouter from 'vue-router'
import router from './router'

Vue.use(VueKonva)
Vue.use(Vue2Filters)
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
